.App {
  text-align: center;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}



.App-header {
  background-color: #303c52;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div-dev{
  margin-top: -15%;
  color: #88c640;
}

@media only screen and (max-width: 500px) {
  .App-logo{
    width: 100%;
  }
  .div-dev{
    margin-top: -100%;
  }
}